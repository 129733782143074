import React, { useState, useEffect, useRef } from "react"
import { navigate } from '@reach/router';
import "./style.scss"
import NewButton from "../NewButton"
import { useWindowScroll, useWindowWidth } from "../../services/hooks"
import WebpImage from "../WebpImage";

const ProductItem = ({ type, product, color, gender, color_scheme, isKidsRelatedGlasses, position="N/A", isSlider }) => {
  const {
    title,
    glassesBasePrice,
    sunglassesBasePrice,
    hubbleFrameVariants,
    sunglassesMaleHeaderImage1,
    sunglassesFemaleHeaderImage1,
    maleHeaderImage1,
    femaleHeaderImage1,
    handle,
    productGender,
  } = product
  const isGlasses = type === "glasses"

  useEffect(() => {
    product.hubbleFrameVariants.map((item) => Object.entries(item)
      .map(([key, value]) => {if ((isGlasses && key.toLowerCase() === "productimage1") || (!isGlasses && key.toLowerCase() === "sunglassesproductimage1")) return value })
      .filter((el) => el))
      .map((item) => item.forEach((image) => {
        const img = new Image()
        img.src = image
      }))
  }, [product])

  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  // Logic for updating product images according to the color selected in the filters
  const filteredColor = hubbleFrameVariants.filter(({ colorFilter }) =>
    color?.some(e => colorFilter.toLowerCase().includes(e))
  )

  useEffect(() => {
    setVariant(color?.length === 0 ? hubbleFrameVariants[0] : filteredColor[0] || variant)
    const newPrimaryImage = isGlasses ? variant.productImage1 : variant.sunglassesProductImage1
    setPrimaryImage(newPrimaryImage)
  }, [color])

  const isKids = product?.size === "Kids" || isKidsRelatedGlasses // second condition is for related products
  const defaultVariant = filteredColor?.length !== 0 ? filteredColor[0] : hubbleFrameVariants[0]
  const [variant, setVariant] = useState(defaultVariant)
  const defaultPrimaryImage = isGlasses
    ? defaultVariant.productImage1
    : defaultVariant.sunglassesProductImage1

  const defaultSecondaryImage = isGlasses
    ? ( isKids ? variant.productImage2 :  maleHeaderImage1)
    : sunglassesMaleHeaderImage1

  const [primaryImage, setPrimaryImage] = useState(defaultPrimaryImage)
  const [secondaryImage, setSecondaryImage] = useState(defaultSecondaryImage)
  const [primaryAltText, setPrimaryAltText] = useState(``)
  const [hoverAltText, setHoverAltText] = useState(``)
  const [price, setPrice] = useState(
    isGlasses ? glassesBasePrice : sunglassesBasePrice
  )
  const [isHovered, setIsHovered] = useState(false)
  const [isImageHovered, setIsImageHovered] = useState(false)
  const itemRef = useRef(null)
  const isWindowScrolled = useWindowScroll()

  const handleScroll = () => {
    // const element = itemRef.current
    // const { top, height } = element.getBoundingClientRect()
    // const middleHeight = typeof window !== "undefined" ? window.innerHeight/2 - height/2 : 0

    // if (top < middleHeight && !mobileHoverOff) setIsImageHovered(true)
    // if (top > middleHeight && !mobileHoverOff) setIsImageHovered(false)
  }

  // useEffect(() => {
  //   if (typeof window !== "undefined" && isMobile) {
  //     window.addEventListener('scroll', handleScroll)
  //   }

  //   return () => {
  //     if (typeof window !== "undefined") {
  //       window.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  // }, [isWindowScrolled])

  useEffect(() => {
    const newImage = isGlasses ? variant.productImage1 : variant.sunglassesProductImage1
    setPrimaryImage(newImage)
  }, [variant])

  const handleChangeVariant = index => {
    const newVariant = hubbleFrameVariants[index]

    const newImage = isGlasses
      ? newVariant.productImage1
      : newVariant.sunglassesProductImage1
    setVariant(hubbleFrameVariants[index])
    setPrimaryImage(newImage)
    setIsImageHovered(false)
  }

  useEffect(() => {
    if(isKids){
      setPrimaryAltText(`${title} eyeglasses from Hubble`)
      setHoverAltText(`A child wearing ${title} eyeglasses from Hubble`)
      return setSecondaryImage(
        variant.productImage2
      )
    }

    if (productGender === "Female") {
      if(isGlasses) {
        setPrimaryAltText(`${title} eyeglasses from Hubble`)
        setHoverAltText(`A woman wearing ${title} eyeglasses from Hubble`)
      } else {
        setPrimaryAltText(`${title} sunglasses from Hubble`)
        setHoverAltText(`A woman wearing ${title} sunglasses from Hubble`)
      }
      return setSecondaryImage(
        isGlasses ? femaleHeaderImage1 : sunglassesFemaleHeaderImage1
      )
    }

    if (gender === "female") {
      if(isGlasses) {
        setPrimaryAltText(`${title} eyeglasses from Hubble`)
        setHoverAltText(`A woman wearing ${title} eyeglasses from Hubble`)
      } else {
        setPrimaryAltText(`${title} sunglasses from Hubble`)
        setHoverAltText(`A woman wearing ${title} sunglasses from Hubble`)
      }
      return setSecondaryImage(
        isGlasses ? femaleHeaderImage1 : sunglassesFemaleHeaderImage1
      )
    }

    if (gender === "male") {
      if(isGlasses) {
        setPrimaryAltText(`${title} eyeglasses from Hubble`)
        setHoverAltText(`A man wearing ${title} eyeglasses from Hubble`)
      } else {
        setPrimaryAltText(`${title} sunglasses from Hubble`)
        setHoverAltText(`A man wearing ${title} sunglasses from Hubble`)
      }
      return setSecondaryImage(
        isGlasses ? maleHeaderImage1 : sunglassesMaleHeaderImage1
      )
    }

    const randomUnisex = ~~(Math.random() * 2)

    if (isGlasses) {
      setPrimaryAltText(`${title} eyeglasses from Hubble`)
      setHoverAltText(
        randomUnisex ? `A woman wearing ${title} eyeglasses from Hubble` : `A man wearing ${title} eyeglasses from Hubble`
      )
      return setSecondaryImage(
        randomUnisex ? femaleHeaderImage1 : maleHeaderImage1
      )
    }

    if (!isGlasses) {
      setPrimaryAltText(`${title} sunglasses from Hubble`)
      setHoverAltText(
        randomUnisex ? `A woman wearing ${title} sunglasses from Hubble` : `A man wearing ${title} sunglasses from Hubble`
      )
      return setSecondaryImage(
        randomUnisex ? sunglassesFemaleHeaderImage1 : sunglassesMaleHeaderImage1
      )
    }
  }, [isImageHovered])

  const handleClick = (e) => {
    e.preventDefault()
    if (typeof window !== "undefined") {
      if (window.gtag !== null) {
        window.gtag('event', 'select_content', {
          "content_type": "product",
          "items": [
            {
              'name': product.title,                      // Name or ID is required.
              'id': product.shopifyProductId,
              'price': product.glassesBasePrice,
              'brand': "Hubble",
              'variant': variant.title,
              "list_position": 1,
              "quantity": 1,
            }
          ]
        })
      }
    }
    navigate(`/${type}/${handle}`)
  }

  return (
    <div
      className={`product-item`}
      data-type={type}
      ref={itemRef}
    >
      <div
        className={`image-container ${(isImageHovered && isKids) ? "hovered" : ""}`}
        onMouseEnter={() => {!isMobile && !isSlider && setIsImageHovered(true);setIsHovered(true)}}
        onMouseLeave={() => {!isMobile && !isSlider && setIsImageHovered(false);setIsHovered(false)}}
        onClick={(e) => { handleClick(e) }}
      >
        <WebpImage className={`product-image ${isImageHovered ? 'hover' : ''}`} fileName={isImageHovered ? secondaryImage : primaryImage} alt={isImageHovered ? hoverAltText : primaryAltText} />
        {/*
        // hide rating
        <div className="rating">
          <span className="text h9 neue-bold">4.5</span>
          <img src={starSvg} alt="" />
        </div> */}
        {isHovered && !isMobile && (
          <div className="button-container">
            <NewButton className="shop-now-button" label="Shop Now" to={`/${type}/${handle}`}  />
          </div>
        )}
      </div>

      <div className="product-info-container">
        <div className="info">
          <p className="title text h5">
            <a href={`/${type}/${handle}`}>
              {title}
            </a>
          </p>
          <p className="price text neue">${price}</p>
        </div>
        <div className="colors-container" role="radiogroup">
          {hubbleFrameVariants.map(({ title, sku}, index) => {
            return (
            <WebpImage className={`swatch ${variant.sku === sku ? "active" : ""}`}
              fileName={`${title}.png`}
              alt={`${title} ${isGlasses ? "Eyeglass Frame" : "Sunglass"} Color`}
              key={`${title}${index}`}
              onClick={() => handleChangeVariant(index)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleChangeVariant(index);
                }
              }}
              role="radio"
              tabIndex={0}
            />
            // <img
            //   className={`swatch ${variant.sku === sku ? "active" : ""}`}
            //   src={`../../images/Swatches/${title
            //     .replace(/\s+/g, "")
            //     .toLowerCase()}.png`}
            //   onClick={() => handleChangeVariant(index)}
            //   alt={`${title} ${isGlasses ? "Eyeglass Frame" : "Sunglass"} Color`}
            //   key={index}
            // />
          )})}
        </div>
      </div>
      {isSlider &&
      <div className="button-container">
        <NewButton className="shop-now-button" label="Shop Now" to={`/${type}/${handle}`} color='black'/>
      </div>}

      {isMobile && !isSlider &&
        <div className="button-container">
          <NewButton className="shop-now-button" label="Shop Now" to={`/${type}/${handle}`} color='black'/>
        </div>
      }
    </div>
  )
}

export default ProductItem
