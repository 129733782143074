import React from 'react'
import './style.scss'
import WebpImage from "../WebpImage";

const ProductTag = ({text, color, label, isPromo, isOtp, className}) => {
  return (
    <div className={`info-tag ${label ? "circle-label" : ""} ${isPromo ? "promo" : ""} ${className || ""}`} data-type={color}>
      {(isOtp || (isPromo && label)) && <WebpImage className="promo-icon" fileName={isOtp ? "Icons/OTP-PromoStar.svg" : "Pages/Intake/Summer-Promo-Icon.svg"} objectFit="contain"/>}
      {isOtp ? <span className='otp-promo'>{text}</span> : text}
    </div>
  )
}

export default ProductTag
